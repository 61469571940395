import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";

const PlanCelebrateSection = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const router = useRouter();

  // Check if the user is logged in on component mount
  useEffect(() => {
    if (Cookies.get("connect.sid")) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleNavigation = (section: string) => {
    // if (isLoggedIn) {
      if (section === "Festivals") {
        router.push("/festivals");
      } else if (section === "Wedding") {
        router.push("/wedding");
      } else if (section === "Events") {
        router.push("/festivals");
      }
    // } else {
    //   router.push("/login");
    // }
  };


  

  const handleSignUpSubmit = (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Optionally, you can validate email and password here

    // Redirect to the signup page
    router.push("https://www.uthsav.com/register/");
  };

  return (
    <section className="plan_celeberate">
      <div className="container-xl container--1">
        <div className="row justify-content-center text-center">
          <div className="col-lg-10">
            <h1 className="planhead">
              Plan any celebration with <img src="images/plan_c.svg" alt="Celebration" />
            </h1>
          </div>
        </div>

        {/* Sign-up Form */}
        <div className="home_sign_up text-center">
          <h5>Sign up today!</h5>
          <p>
            By clicking ‘Sign up’, you agree to Uthsav’s Privacy Policy and Terms of
            Use.
          </p>
          <form onSubmit={handleSignUpSubmit}>
            <div className="row justify-content-center">
              <div className="col-xl-7">
                <div className="sign_up_form">
                  <div className="row justify-content-center">
                    <div className="col-xl-4">
                      <input
                        type="email"
                        className="txt"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-xl-4">
                      <input
                        type="password"
                        className="txt"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-xl-3">
                      <input
                        type="submit"
                        className="smit"
                        defaultValue="SIGN UP"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* Walk You Through Section */}
        <div className="walk_you text-center">
          <h4>We’ll walk you through every part of planning</h4>
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <p>
                Uthsav welcomes you to start planning your next event (whether it's
                a festival, wedding, or family event) with us.
              </p>
            </div>
          </div>
        </div>

        {/* Plan Sections */}
        <div className="row justify-content-center text-center">
          <div className="col-xl-10">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-lg-5 mb-4">
                <div className="home_plan_box">
                  <div
                    className="home_plan_box_img"
                    style={{ backgroundImage: "url(images/img7.png)" }}
                  />
                  <h6
                    className="zoom-effect"
                    onClick={() => handleNavigation("Festivals")}
                  >
                    Festivals
                  </h6>
                  <p>
                    India celebrates over 45 national festivals and over 100+
                    religious festivals. Host your next festival at home or in
                    public with Uthsav.
                  </p>
                  <a
                    href="/festivals"
                    className="gt_start"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavigation("Festivals");
                    }}
                  >
                    Get Started
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-lg-5 mb-4">
                <div className="home_plan_box">
                  <div
                    className="home_plan_box_img"
                    style={{ backgroundImage: "url(images/img8.png)" }}
                  />
                  <h6
                    className="zoom-effect"
                    onClick={() => handleNavigation("Wedding")}
                  >
                    Wedding
                  </h6>
                  <p>
                    Elaborate, Glamorous, and Scintillating. Plan your next wedding
                    with Uthsav.
                  </p>
                  <a
                    href="/wedding"
                    className="gt_start"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavigation("Wedding");
                    }}
                  >
                    Get Started
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-lg-5 mb-4">
                <div className="home_plan_box">
                  <div
                    className="home_plan_box_img"
                    style={{ backgroundImage: "url(images/img9.png)" }}
                  />
                  <h6
                    className="zoom-effect"
                    onClick={() => handleNavigation("Events")}
                  >
                    Events
                  </h6>
                  <p>
                    Birthdays, anniversaries, baby showers, retirement, whatever
                    your event may be. Plan it with Uthsav.
                  </p>
                  <a
                    href="/festivals"
                    className="gt_start"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavigation("Events");
                    }}
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanCelebrateSection;
