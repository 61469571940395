import { Box, Container, Grid } from "@mantine/core";
import Link from "next/link";

import TopBannerSection from "@/components/TopBannerSection";
import PlanCelebrateSection from "@/components/PlanCelebrateSection";
import { getQueryClient } from "@/api";
import { contract } from "contract";
import { BlogsViewType } from "contract/enum";


const Homepage = () => {
  const vendorCategories = [
    { name: "Venues", className: "venu" },
    { name: "Photographers", className: "ptograp" },
    { name: "Makeup", className: "makup" },
    { name: "Weddingshoot", className: "wedshoot" },
    { name: "Planning", className: "planing" },
    { name: "Bridal", className: "bridal" },
  ];


  const {
      data,
      isLoading,
      error,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
    } = getQueryClient().blogs.getAllBlogs.useInfiniteQuery(
      [contract.blogs.getAllBlogs.path,BlogsViewType.all],
      ({ pageParam }) => {
        return {
          query: {
            pageSize: 10,
            pageNumber: pageParam?.pageNumber ?? 1,
            blogsViewType: BlogsViewType.all,
          },
        };
      },
      {
        getNextPageParam: (lastPage, allPages) => {
          if (
            lastPage.status === 200 &&
            lastPage.body.currentPageNumber < lastPage.body.totalPages
          ) {
            return { pageNumber: lastPage.body.currentPageNumber + 1 };
          }
          return undefined;
        },
      }
    );

    const results = data?.pages.flatMap((eachPage) => {
      return eachPage.body.results;
    });

  return (

    
    <Box pos="relative" w="100%" mih="100vh">
      

     <TopBannerSection />
     <PlanCelebrateSection/>
     
      {/* Resources Section */}
      <section className="home_resource">
        <Container size="xl" className="container--1">
          <Grid justify="center">
            <Grid.Col span={{ base: 12, xl: 8 }} ta="center">
              <h3 className="subhead">Resources</h3>
              <p className="plstag">
                Uthsav has a lot of helpful resources and tips to help you make the most of your event--blogs, videos, expert advice and more. Dive right in, and feel free to drop us a note. One of our vast team of resources will be live to talk to you.
              </p>
            </Grid.Col>
          </Grid>

          <Grid justify="center" className="pt-5" pt={5}>
            <Grid.Col span={{ base: 12, sm: 10 }}>
              <Grid>
                {/* Sample Resource Boxessss */}
                {results?.slice(0, 6).map((num) => (
                  <Grid.Col
                    key={num.id}
                    span={{ base: 12, md: 6, lg: 4 }}
                    mb={4}
                    className="col-lg-4 col-md-6 mb-lg-5 mb-4 pr-5 pl-5"
                  >
                    <Box className="home_res_box">
                      <Box
                        className="home_res_box_img"
                        style={{ backgroundImage: `url(${num.blogsImage})` }}
                      />
                      <h6>{num.blogsName}</h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            num.blogsDescription.length > 60
                              ? `${num.blogsDescription.substring(0, 65)}...`
                              : num.blogsDescription,
                        }}
                      />
                      <Link
                        href={`/blogsDetails/${num.id}`}
                        passHref
                        className="lm_more"
                      >
                        Learn more
                      </Link>
                    </Box>
                  </Grid.Col>
                ))}
              </Grid>
            </Grid.Col>
          </Grid>
        </Container>
      </section>

      {/* Featured Vendors Section */}
      {/* <section className="featured_vendors">
        <Container size="xl" className="container--1">
          <Grid justify="center" className="pb-5">
            <Grid.Col span={{ base: 12, sm: 8 }} ta="center">
              <h3 className="subhead">Featured Vendors</h3>
              <p className="plstag">
                We are building a comprehensive list of vendors--from photographers, flowers, venues, and so on. Check them out and connect with them directly!
              </p>
            </Grid.Col>
          </Grid>

          <Grid justify="center" pt={5}>
  <Grid.Col span={{ base: 12, sm: 10 }}>
    <Grid>
     
      {vendorCategories.map((vendor, index) => (
        <Grid.Col key={index} span={{ base: 12, md: 6 }}>
          <Link href="/browse-vendors" passHref>
            <Box className={`feat_vendor_box ${vendor.className}`}>
              <Box className="feat_vendor_box_lft">
                <h6>{vendor.name}</h6>
              </Box>
              <Box className="feat_vendor_box_img">
                <img
                  src={`/images/${vendor.name.toLowerCase().replace(" &", "").replace(" ", "")}.png`}
                  alt={vendor.name}
                />
              </Box>
            </Box>
          </Link>
        </Grid.Col>
      ))}
    </Grid>
  </Grid.Col>
</Grid>
        </Container>
      </section> */}
    </Box>
  );
};

export default Homepage;
