import { Box, Container, Grid } from "@mantine/core";
import "bootstrap/dist/css/bootstrap.min.css";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";

const TopBannerSection = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (Cookies.get("connect.sid")) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleNavigation = (section: string) => {
    // if (isLoggedIn) {
      if (section === "Festivals") {
        router.push("/festivals");
      } else if (section === "Weddings") {
        router.push("/wedding");
      } else if (section === "Events") {
        router.push("/festivals");
      }
    // } else {
    //   router.push("/login");
    // }
  };

  return (
    <div className="top_ban">
      <div className="row g-0">
        {/* Festival Section */}
        <div className="col-lg-4 hm_bx_11">
          <div
            className="hm_bx hm_bx_1"
            style={{
              backgroundImage: "url('/images/festival-image.png')",
              backgroundSize: "cover",
              height: "100%",
            }}
          >
            <div className="tp_heads">
              <h2
                className="zoom-effect"
                onClick={() => handleNavigation("Festivals")}
              >
                Festivals
              </h2>
            </div>
          </div>
        </div>

        {/* Weddings Section */}
        <div className="col-lg-4 hm_bx_22">
          <div
            className="hm_bx hm_bx_2"
            style={{
              backgroundImage: "url('/images/weddings.png')",
              backgroundSize: "cover",
              height: "100%",
            }}
          >
            <div className="tp_heads">
              <h2
                className="zoom-effect"
                onClick={() => handleNavigation("Weddings")}
              >
                Weddings
              </h2>
            </div>
          </div>
        </div>

        {/* Events Section */}
        <div className="col-lg-4 hm_bx_33">
          <div
            className="hm_bx hm_bx_3"
            style={{
              backgroundImage: "url('/images/events.png')",
              backgroundSize: "cover",
              height: "100%",
            }}
          >
            <div className="tp_heads">
              <h2
                className="zoom-effect"
                onClick={() => handleNavigation("Events")}
              >
                Events
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBannerSection;
